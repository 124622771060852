<template>
  <div class="cooperation">
    <van-nav-bar :title="showqrText" left-arrow @click-left="onClickLeft" />
    <div class="banner">
      <van-image
        :width="screenWidth"
        :height="(screenWidth / 800 * 360)"
        lazy-load
        src="https://img.midbest.cn/uploads/image/order/kongjia/2020/10/17/2883c48dc5682c433f3b81ba29057389.jpg"
      />
    </div>
    <div class="hezuo_title">
      供货合作联系方式
    </div>
    <div class="worktime">
      <van-cell title="工作时间：" value="周一至周五 08:30-12:00 13:00-18:00 (法定节假日除外)" />
    </div>
    <div class="lianxifangshi">
      <van-cell :border="false" label="本微信只通过供货方添加（加好友时请备注品牌名称和产品类别，通过申请后请直接留言合作内容我们会一一回复）。">
          <template #icon>
            供货合作：
          </template>
          <template #title>
            <van-image
              src="https://img.midbest.cn/uploads/kefu/17.jpg?t=1"
              style="margin-right: 10px"
              width="100px"
              height="100px"
              lazy-load
              @click="yulan(0)"
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
            <van-image
              src="https://img.midbest.cn/uploads/kefu/20.jpg"
              width="100px"
              height="100px"
              lazy-load
              @click="yulan(1)"
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </template>
        </van-cell>
    </div>
  </div>
</template>
<script>
import { ImagePreview } from 'vant';
export default {
  name: "Cooperation",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      userinfo: {},
      showqrText: "供货合作", // 二维码的名称
      qrcodeImg: "", // 默认没有二维码
      isLogin: localStorage.getItem("DK_ISLOGIN")
        ? localStorage.getItem("DK_ISLOGIN")
        : false,
      shareid: 0,
      merchid: 0
    };
  },
  mounted() {
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;

    this.$parent.getmerchname(this.merchid, this.showqrText);
    
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    bohao(num) {
      window.location.href="tel:" + num;
    },
    copaywetch(code) {
      let _that = null;
      _that = this;
      _that.$copyText(code).then(
        function(e) {
          _that.$toast("复制成功");
          console.log(e);
        },
        function(e) {
          console.log(e);
        }
      );
    },
    yulan(index) {
      ImagePreview({
        images: [
          'https://img.midbest.cn/uploads/kefu/17.jpg?t=1',
          'https://img.midbest.cn/uploads/kefu/20.jpg',
        ],
        startPosition: index,
      });
    },
  }
};
</script>
<style lang="less">
.cooperation {
  height: 100%;
  width: 100%;
  background: #fff;
  .van-nav-bar__left .van-icon {
    color: #333;
  }
  .hezuo_title {
    padding: 20px 20px 0;
    text-align: center;
    font-size: 30px;
    color: #333;
    font-weight: 500;
  }
  .worktime {
    padding: 20px 5px 0;
    .van-cell {
      .van-cell__title {
        width: 75px;
        text-align: left;
        color: #999;
      }
      .van-cell__value {
        flex: 1;
        text-align: left;
        color: #333;
      }
    }
  }
  .lianxifangshi {
    padding-bottom: 60px;
    overflow: hidden;
    .van-cell {
      line-height: 30px;
      background-color: #fff;
      align-items:center;
      .van-cell__title {
        flex: 1;
        text-align: left;
        color: #999;
      }
    }
  }

}
</style>
